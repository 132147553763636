/* This stylesheet generated by Transfonter (https://transfonter.org) on October 26, 2017 3:40 AM */

@font-face {
	font-family: 'PT_Sans-Regular';
	src: url('../fonts/PTSans/PTSans-Regular.eot');
	src: url('../fonts/PTSans/PTSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PTSans/PTSans-Regular.woff') format('woff'),
		url('../fonts/PTSans/PTSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Space_Mono-Bold';
	src: url('../fonts/SpaceMono/SpaceMono-Bold.eot');
	src: url('../fonts/SpaceMono/SpaceMono-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SpaceMono/SpaceMono-Bold.woff') format('woff'),
		url('../fonts/SpaceMono/SpaceMono-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Space_Mono-Regular';
	src: url('../fonts/SpaceMono/SpaceMono-Regular.eot');
	src: url('../fonts/SpaceMono/SpaceMono-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SpaceMono/SpaceMono-Regular.woff') format('woff'),
		url('../fonts/SpaceMono/SpaceMono-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Mark-Heavy';
    src: url('../fonts/Mark/Mark-Heavy.eot');
    src: url('../fonts/Mark/Mark-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mark/Mark-Heavy.woff') format('woff'),
        url('../fonts/Mark/Mark-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
	font-family: 'Mark-Black';
	src: url('../fonts/Mark/Mark-Black.eot');
	src: url('../fonts/Mark/Mark-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mark/Mark-Black.woff') format('woff'),
		url('../fonts/Mark/Mark-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Mark-Medium';
	src: url('../fonts/Mark/Mark-Medium.eot');
	src: url('../fonts/Mark/Mark-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mark/Mark-Medium.woff') format('woff'),
		url('../fonts/Mark/Mark-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Mark-Regular';
	src: url('../fonts/Mark/Mark-Regular.eot');
	src: url('../fonts/Mark/Mark-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mark/Mark-Regular.woff') format('woff'),
		url('../fonts/Mark/Mark-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
