$color-list: (
  --blue: #12355B,
  --blue__light: #687685,
  --yellow: #FDFC48,
  --green: #73E0B7,
  --green__opacity: #00d484,
  --grey: #7A7A7A,
  --grey__light: #fdfdfd,
  --white: #ffffff
);
// use map-get($color-list, [name])
