body{
  background-image: url(../images/codigo-super-graphic-artboard-3.png);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  color: map-get($color-list, --blue);

  *{
  letter-spacing: 1px;
  }
}

.sidebar-part2 .right-bg{
  -webkit-box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.3);
  box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.3);
}
.alt-font{
  font-family: 'Mark-Black';
}

a.hover-green:hover{
  color: map-get($color-list, --green ) !important;
}


@each $key, $value in $color-list {
  .text#{$key} {
    color: $value;
  }
  .bg#{$key} {
    background-color: $value;
  }
}

.bg-transparent{
  background: transparent;
}

.font--space__mono{
  font-family: 'Space_Mono-Regular';
  &--bold{
    font-family: 'Space_Mono-Bold';
  }
}
.font--mark{
  font-family: 'Mark-Regular';
  &--heavy{
    font-family: 'Mark-Heavy';
  }
  &--black{
    font-family: 'Mark-Black';
  }
  &--medium{
    font-family: 'Mark-Medium';
  }
}

.btn{
  font-family: 'Space_Mono-Regular';
  text-transform: none;
}

.font--pt__sans{
  font-family: 'PT_Sans-Regular';
}

.md-pos-minus-30px-left{
  @media screen and(min-width: 990px) {
    position: relative;
    left: -30px;
  }
}

.mfp-close{
  @media screen and (max-width: 425px) {
    top: 50px;
  }
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
  color: map-get($color-list, --blue);
}
.portfolio-filter-tab-1 li a{
  color: map-get($color-list, --blue);
}

hr{
  border-top-color: lighten(map-get($color-list, --blue), 60%);
  &.--blue{
    border-top-color: map-get($color-list, --blue);
  }
}


.bg__green--left{
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 38%;
    height: 100%;
    background-color:map-get($color-list, --green);
    background-image: url(../images/codigo-super-graphic-artboard-3.png);
    background-position: fixed;
    background-attachment: fixed;
    @media screen and (max-width: 425px) {
      width: 75%;
    }
  }
}

@for $i from 1 through 10 {
  .bg__width--#{$i}0{
    &:before{
      width: #{$i}0%;
    }
  }
  .sm--bg__height--#{$i}0{
    @media screen and (max-width: 425px) {
      &:before{
        height: #{$i}0%;
      }
    }
  }
}


.sm--bg__width--100{
  @media screen and (max-width: 425px) {
    &:before{
      width: 100%;
    }
  }
}
.sm--bg__pos--bottom{
  @media screen and (max-width: 425px) {
    &:before{
      top: auto;
      bottom:0;
    }
  }
}


.bg__green--right{
  @extend .bg__green--left;
  &:before{
    left: auto;
    right: 0;
  }
}

.bg__green--right--half{
  @extend .bg__green--right;
  &:before{
    width: 50%;
  }
}

.bg__yellow--right{
  @extend .bg__green--left;
  &:before{
    left: auto;
    right: 0;
    background-color: map-get($color-list, --yellow);
    @media screen and (max-width: 425px) {
      width: 75%;
    }
  }
}
.bg__green--full{
  @extend .bg__green--left;
  &:before{
    width: 100%;
  }
}

.bg__green--half{
  @extend .bg__green--left;
  &:before{
    width: 50%;
  }
}

.bg__career{
  background-image: url(../images/background-career.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.box__underline{
  max-width: 200px;
  margin: auto;
  transform: translate(80px, -25px);
  .line{
    background-color: map-get($color-list, --yellow);
    width: 30px;
    height: 10px;
    margin: 0 5px;
    display: inline-block;
    &--short{
      width: 10px;
    }
  }
  &--small{
    transform: translate(80px, -30px);
    .line{
      height: 4px;
      &--short{
        height: 4px;
      }
    }
  }
}

.container{
  margin: auto;
}

.main__title{
  text-transform: uppercase;
  letter-spacing:3px;
}

iframe{
  width: 100%;
  height: 300px;
}

a:hover{
  color: map-get($color-list, --yellow);
}

.mission{
  p{
    position: relative;
    max-width: 240px;
    span{
      position: absolute;
      left: -60px;
      top: 10px;
      font-size: 42px;
      color: map-get($color-list, --grey);
      opacity: .3;
    }
  }
}

.text__hero{
  font-size: 70px;
  line-height: 1.1;
  letter-spacing: 4px;
  @media screen and (max-width: 425px) {
    font-size: 32px;
  }
}
.subtitle__hero{
  font-size: 16px;
  letter-spacing: .5px;
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
}

.nav-tagline{
  transform: rotate(-90deg) translate(-50%, -50%);
  top: 35%;
  left: -40%;
  position: absolute;
  width: 180px;
  display: block;
  text-transform: uppercase;
}

.btn__green{
  color: map-get($color-list, --white);
  background-color: map-get($color-list, --green) !important;
  border: 1.5px solid map-get($color-list, --green);
  &:hover{
    color: map-get($color-list, --green);
    background: transparent !important;
  }
}

figcaption{
  margin-top: 5px;
}

.mfp-bg{
  background: map-get($color-list, --blue);
  opacity: .9;
}
.input__codigo{
  &::placeholder{
    color: darken(white, 20%);
  }
  &:active, &:focus{
    border-color: map-get($color-list, --green);
  }
}

.right-col{
  float: right;
}

.contact__link:hover{
  color: map-get($color-list, --blue);
}

header .sidebar-part2 nav.navbar.bootsnav ul > li > a{
  color: map-get($color-list, --blue);
  &:hover{
    color: map-get($color-list, --green);
  }
}

.nav-icon:hover span, .nav-icon.active span{
  background-color: map-get($color-list, --blue);
}

.bg-blue-opacity{
  background-color: rgba(64, 90, 119, 0.85);
}

.box__product{
  .logo{
    margin-bottom: 30px;
    margin-top: -10px;
  }
  label{
    display: block;
  }
  p{
    margin: 5px 0;
  }
}

.bg__plus{
  background-image: url(../images/bg-plus.svg);
  background-position: left center;
  background-repeat: no-repeat;
}

.bg__line{
  position: relative;
  &:before{
    content: url(../images/line-1.svg);
    position: absolute;
    left: 90px;
    bottom: 30px;
    height: 20px;
  }
  &:after{
    content: url(../images/line-2.svg);
    position: absolute;
    left: 90px;
    bottom: 0;
    height: 20px;
  }
}

.codigo__scroll{
  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    width: 95%;
  }
  &::-webkit-scrollbar{
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: map-get($color-list, --green);
    outline: 1px solid slategrey;
    left: 100px;
  }
}


.box-video{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  

  video{
    transform: scale(1.2 , 1.2);
  }

  iframe{
    widows: 100%;
    height: 100%;
  }
}

.box-img-blog{
  width: 360px;
  height: 240px;
  // background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.box-img-circle{
  @extend .box-img-blog;
  width: 150px;
  height: 150px;
  border-radius: 1000px;
}


// SLIDER
.swiper-codigo-slides{
  .swiper-wrapper{
    max-width: 600px;
  }
}

video{
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
}

.list-clean{
  list-style-type: none;
}

.sm-width-thirty-lr{
  @media screen and(max-width:460px) {
    width: 30% !important;
  }
}

.box-img-mobile{
  display: none;
  @media screen and (max-width: 625px){
    display: block;
    width: auto;
    margin: auto;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
  }
}
.box-video-desktop{
  display: block;
  @media screen and (max-width: 625px){
    display: none;
  }
}
._line{
  position: absolute;
  display: block;
  margin: 0 5px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 29px;
  height: 2px;
  background: map-get($color-list, --green );

  &:after{
    content: '';
    width: 10px;
    height: 2px;
    background: map-get($color-list, --green );
    position: absolute;
    right: -20px;
  }

  &:nth-child(2){
    left: 72.5%;
  }
}

.modal{
  background-color: rgba(17, 56, 99, 0.7);
}

.box-captcha{
  width: 100%;
  min-height: 50px;
  margin-bottom: 30px;
}


.scroll{
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%) scale(.8,.8);
  width: 30px;
  height: 45px;
  border-radius: 50px;
  border: 2px solid white;
  &:after{
    content: '';
    width: 2px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 10px;
    animation: mouse .5s infinite ease alternate;
  }
  @media screen and (max-width:625px) {
    display: none;
  }
}

@keyframes mouse{
  from{top:5px}
  to{top:10px}
}

.socmed-responsive{
  @media screen and (max-width: 740px){
    a{
      display: inline-block;
      width: 60px;
      margin: 20px;
    }
  }
}

.uploader{
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
}

.video-js{
  background: #14385D !important;
}

.cover-video-mobile{
  width: 100%;  
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  padding: 30px;
  display: none;

  @media screen and (max-width: 640px ){
    display: block;
  }
}

#mainVideo{
  background: #14385d;
  @media screen and (max-width: 640px){
    display: none;
  }
}