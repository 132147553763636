.hero__headline{
  // line-height: 1.2;
}

.text-green-hover:hover{
  color: map-get($color-list, --green);
}

.codigo__input-file{
  min-height: 55px;
  .input__codigo{
    width: 75%;
    float: left;

    @media screen and (max-width: 460px) {
      width: 65%;
    }
    @media screen and (max-width: 375px) {
      width: 60%;
    }
    @media screen and (max-width: 375px) {
      width: calc(55% - 10px);
    }
    
    // @media screen and (min-width: 1440px) and (max-width: 1800px) {
    //   max-width: 460px;
    // }
    // @media screen and (min-width: 1900px){
    //   max-width: 640px;
    // }
    // @media screen and (max-width:460px){
    //   max-width: 235px;
    // }
    // @media screen and (max-width:400px){
    //   max-width: 190px;
    // }
    // @media screen and (max-width:320px){
    //   max-width: 145px;
    // }
  }
  .input-group-btn{
    display: inline-block;
    position: relative;
    width: calc(25% - 10px);
    box-sizing: border-box;
    float: right;
    @media screen and (max-width: 460px) {
      width: calc(35% - 10px);
    }
    @media screen and (max-width: 375px) {
      width: calc(40% - 10px);
    }
    @media screen and (max-width: 320px) {
      width: calc(45% - 10px);
    }
    .btn-choose{
      width:100%;
      padding: 8px 10px;
      border-radius: 0;
    }
  }
}

.notif-mobile{
  position: relative;
  float: left;
  display: block;
  top: -15px;
  margin-bottom: 5px;
  color: white;
  display: none;
  @media screen and (max-width: 460px) {
    display: block;
  }
}

.modal-padding-responsive{
  @media screen and (max-width: 1024px){
    padding: 30px;
  }
  @media screen and (max-width: 768px){
    padding: 110px;
  }
  @media screen and (max-width: 450px){
    padding: 20px;
    padding-top: 100px;
  }
}

textarea{
  overflow-x: hidden;
}

.mfp-close, .mfp-close:active{
  @media screen and (max-width:768px){
    top: 50px;
  }
}

.box-error{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  font-family: 'Mark-Medium';

  
  h1{
    color: #73E0B7;
    font-size: 160px;
    font-weight: bold;
    @media screen and (max-width: 600px){
      font-size: 120px;
    }
    @media screen and (max-width: 320px){
      font-size: 70px;
    }
    img{
      top: -35px;
      margin-left: 15px;
      margin-right: 15px;
      animation: updown ease infinite 1s alternate;
      position: relative;
      @media screen and (max-width: 600px){
        width: 100px;
      }
      @media screen and (max-width: 320px){
        width: 70px;
        margin-left: 7px;
        margin-right: 7px;
      }
    }
  }
  span{
    font-size: 30px;
    font-weight: bold;
    @media screen and (max-width: 320px){
      font-size: 24px;
    }
  }
  p{
    font-size: 18px;
    margin-top: 9px;
    @media screen and (max-width: 320px){
      font-size: 12px;
    }
  }
}

@keyframes updown{
  from{top: -10px}
  to{top: -35px}
}

.box-line{
  position: absolute;
  img{
    display: block;
  }
  &--top-right{
    top: 76px;
    right: 0;
    img{
      margin-bottom: 24px;
      &:last-child{
        float: right;
      }
    }
    @media screen and (max-width: 680px){
      top: 26px;
    }
  }
  &--bottom-left{
    bottom: 76px;
    left: 0;
    img{
      margin-top: 24px;
    }
    @media screen and (max-width: 680px){
      bottom: 26px;
    }
  }
}